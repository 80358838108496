import dayjs from "dayjs";
import { t } from "i18next";

export function checkIsValidPermit(emp) {
  // console.log("🚀 ~ checkIsValidPermit ~ permitDate:", emp?.permitNumber?.length)
  // console.log("🚀 ~ checkIsValidPermit ~ getExpiredPermitDate(permitDate):", getExpiredPermitDate(permitDate))
  if (emp?.hasPermit === false) {
    return { isValid: false, message: t('Not Available') }
  }
  if (emp?.permitDate == null || emp?.permitDate == undefined || emp?.permitDate?.length == 0) {
    return { isValid: false, message: t("Date d'obtention du permis non fourni") }
  }
  if (emp?.permitNumber == null || emp?.permitNumber == undefined || emp?.permitNumber?.length == 0) {
    return { isValid: false, message: t('Numéro de permis non fourni') }

  }
  return new Date() < getExpiredPermitDate(emp?.permitDate) ? { isValid: true, message: t('Valide') } : { isValid: false, message: t('Expiré') }
}

function getLastContract(contracts) {
  if (!contracts || contracts?.length < 1) {
    return null
  }

  if (contracts?.length == 1) {
    return contracts[0];
  }

  return contracts?.sort((a, b) => new Date(b?.permitDate) - new Date(a?.permitDate))[0]

}

function getExpiredPermitDate(permitDate) {
  return permitDate ? new Date(new Date(permitDate).setFullYear(new Date(permitDate).getFullYear() + 2)) : null
}

export function checkContractDate(employe) {
  const contract = getLastContract(employe?.contracts);

  if (contract != null && contract?.permitDate != null && contract?.permitDate != undefined) {
    return new Date() < getExpiredPermitDate(contract?.permitDate)
  }

  return false;
}

export function getLastContractPermitDate(employe) {
  const contract = getLastContract(employe?.contracts);

  if (contract != null && contract?.permitDate != null && contract?.permitDate != undefined) {
    // console.log("EXP ", getExpiredPermitDate(contract?.permitDate))
    return dayjs(getExpiredPermitDate(contract?.permitDate)).format("DD-MM-YYYY")
  }

  return t("contract-notfound");
}

export function formatMoney(number) {

  return number?.toLocaleString()
}