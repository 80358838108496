import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import usePostAxiosData from "../../../hooks/usePostAxiosData";
import useDataFetching from "../../../hooks/useDataFetching";
import { config } from "../../../constants/config";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { t } from "i18next";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Col, Container, Form, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const API_URL = `${process.env.REACT_APP_API_URL}/`;

const AddEmployerModal = ({ open, handleClose, fetch, employer }) => {
  console.log("🚀 ~ AddEmployerModal ~ employer:", employer)
  const { i18n } = useTranslation()
  /** Form validation config */
  const schema = yup.object().shape({
    firstName: yup.string().required(t("Ce champ est requis")),
    lastName: yup.string().required(t("Ce champ est requis")),
    // contratNumber: yup.string().required(t("Ce champ est requis")),
    // contratDuration: yup.number().min(3).required('Ce champ est requis'),
    // salary: yup.number().required(t("Ce champ est requis")),
    // Csalary: yup.number().required(t("Ce champ est requis")),

    // salary: yup.number().positive().required(t("Ce champ est requis")),
    // baseSalary: yup.number().positive().required(t("Ce champ est requis")),
    // indLogement: yup.number().min(0),
    // indTransport: yup.number().min(0),
    // indResponsabilite: yup.number().min(0),
    // indRepresentation: yup.number().min(0),
    // primeExploitation: yup.number().min(0),
    // sursalaire: yup.number().min(0),
    // primeSalissure: yup.number().min(0),
    // indCaisse: yup.number().min(0),
    // bonus: yup.number().min(0),
    // primePanier: yup.number().min(0),
    // billetAvion: yup.number().min(0),
    // autresPrimes: yup.number().min(0),

    // prime: yup.number().required(t("Ce champ est requis")),
    // frais: yup.number().required(t("Ce champ est requis")),
    // other: yup.number().required(t("Ce champ est requis")),

    visaNumber: yup.string(),
    // indenmite: yup.string().required(t("Ce champ est requis")),
    passportNumber: yup.string().required(t("Ce champ est requis")),
    // qualification: yup.string().required(t("Ce champ est requis")),
    // resLatitude: yup.string().required(t("Ce champ est requis")),
    // resLongitude: yup.string().required(t("Ce champ est requis")),
    // permitNumber: yup.string(),
    // residencePermitNumber: yup.string(),
  });
  // const auth = useAuthUser();
  const [canEdit, setCanEdit] = useState(true);
  const [sexe, setSexe] = useState(null);
  const [nationalities, setNationalities] = useState([]);
  // const [contratDuration, setcontratDuration] = useState(null);
  const [hasPermit, setHasPermit] = useState("true");
  const [date, setDate] = useState(null);

  const [hasResidentPermit, setHasResidentPermit] = useState("true");
  const [residencePermitExpDate, setResidencePermitExpDate] = useState(null);

  const [birthDat, setBirthDat] = useState(null);
  const [visaExpDate, setvisaExpDate] = useState(null);
  const [passportExpDate, setpassportExpDate] = useState();
  const [diplome, setDiplonme] = useState(null);
  const [occupationId, setOccupationId] = useState(null);
  const [occupationName, setOccupationName] = useState(null);
  const [category, setCategory] = useState(null);
  // const [enterprise, setEnterprise] = useState(null);
  const [nationality, setNationality] = useState("");
  const { user: currentUser } = useSelector((state) => state.auth);


  // const durations = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  const [compte, setCompte] = useState('');
  const fetchCompte = () => {
    // setSuccessful(true);
    axios
      .get(API_URL + "comptes" + "/" + currentUser?.user?.compte_id, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((response) => {
        // setSuccessful(false);
        setCompte(response.data?.data);
        // if(response.data?.data?.immatriculation) setShowimmatriculation(true)
        // console.log("compte : ", response.data?.data);
      })
      .catch((e) => {
        // setSuccessful(false);
        setCompte(null);
      });
  };
  useEffect(() => {
    fetchCompte()
  }, [currentUser]);
  //   fetch diplome
  const [loadingD, errorD, diplomes, fetchDataD] = useDataFetching(
    `${config.app.fntec_url}/diplomes`
  );

  //   fetch categories
  const [loadingC, errorC, categories, fetchDataC] = useDataFetching(
    `${config.app.fntec_url}/categories`
  );

  //   fetch categories
  const [loadingE, errorE, enterprise, fetchDataE] = useDataFetching(
    `${config.app.fntec_url}/enterprises/search-by-nui/${compte?.nui}`
  );

  // console.log("🚀 ~ AddEmployerModal ~ enterprise:", enterprise?.length)
  // console.log("🚀 ~ AddEmployerModal ~ enterprise:", enterprise)
  //   fetch categories
  const [loadingO, errorO, occupations, fetchDataO] = useDataFetching(
    `${config.app.fntec_url}/occupations`
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // console.log("🚀 ~ AddEmployerModal ~ errors:", errors)

  const onSubmitHandler = (data) => {
    handleSave(data);
    // reset();
  };



  /** post create employer */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`employers`, "POST");

  const handleSave = async (formData) => {
    const data = {
      ...formData,
      id: employer?.id || null,
      // fullName: formData?.fullName,
      sexe: sexe,
      // salary: formData?.salary,
      // indLogement: formData?.indLogement,
      // indTransport: formData?.indTransport,
      // indResponsabilite: formData?.indResponsabilite,
      // indRepresentation: formData?.indRepresentation,
      // primeExploitation: formData?.primeExploitation,
      // sursalaire: formData?.sursalaire,
      // primeSalissure: formData?.primeSalissure,
      // indCaisse: formData?.indCaisse,
      // bonus: formData?.bonus,
      // primePanier: formData?.primePanier,
      // billetAvion: formData?.billetAvion,
      // autresPrimes: formData?.autresPrimes,
      // sursalary: formData?.Csalary,
      // sursalary: formData?.Csalary,


      // frais: formData?.frais,
      // other: formData?.other,
      // prime: formData?.prime,
      // indenmite: formData?.indenmite,
      nationality: nationality,
      // creatorId: currentUser?.user?.compte_id,

      // contratNumber: formData?.contratNumber,
      // contratDuration: parseInt(contratDuration),

      visaNumber: formData?.visaNumber,
      visaExpDate: visaExpDate ? dayjs(visaExpDate).format("YYYY-MM-DD") : null,


      passportNumber: formData?.visaNumber,
      passportExpDate: passportExpDate ? dayjs(passportExpDate).format("YYYY-MM-DD") : null,

      // permitNumber: formData?.permitNumber,
      // permitDate: date ? dayjs(date).format("YYYY-MM-DD") : null,
      birthDate: birthDat ? dayjs(birthDat).format("YYYY-MM-DD") : null,

      // hasPermit: hasPermit,
      // hasPermit: hasPermit == "Oui" ? "true" : "false",
      diplomeId: parseInt(diplome),
      occupationId,
      occupationName,
      // categoryId: parseInt(category),

      // resLatitude: parseFloat(formData.resLatitude).toFixed(4),
      // resLongitude: parseFloat(formData.resLongitude).toFixed(4),
      enterpriseId: parseInt(enterprise?.data?.id),
      // enterpriseNui : compte?.nui,

      // hasResidentPermit,
      // residencePermitExpDate: residencePermitExpDate ? dayjs(residencePermitExpDate).format("YYYY-MM-DD") : null,


    };

    // console.log("data to saved", data);
    await postAxiosData(data);
  };

  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      reset({
        firstName: "",
        lastName: "",
        contratNumber: "",
        // contartDuration: undefined,
        // salary: null,
        Csalary: null,
        visaNumber: "",
        indenmite: "",
        passportNumber: "",
        permitNumber: "",
        qualification: "",
        prime: null,
        frais: null,
        other: "",
        resLatitude: "",
        resLongitude: "",
      });
      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : "Employe ajouté avec succès."
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );
      fetch()
      handleClose()

      // handleSignin(result);
    }
  }, [successMessage]);

  /* fetch nationalities */
  useEffect(() => {
    if (nationalities.length <= 0) {
      axios.get("https://restcountries.com/v3.1/all").then((response) => {
        setNationalities(response.data);
      });
    }
  }, []);

  useEffect(() => {
    if (employer) {
      // alert(employer)
      setCanEdit(false)
      setValue('firstName', employer?.firstName)
      setValue('lastName', employer?.lastName)
      // setValue('contratNumber', employer?.contratNumber)
      // setValue('salary', employer?.salary || 0)
      // setValue('baseSalary', employer?.baseSalary || 0)
      // setValue('indLogement', employer?.indLogement || 0)
      // setValue('indTransport', employer?.indTransport || 0)
      // setValue('indResponsabilite', employer?.indResponsabilite || 0)
      // setValue('indRepresentation', employer?.indRepresentation || 0)
      // setValue('primeExploitation', employer?.primeExploitation || 0)
      // setValue('sursalaire', employer?.sursalaire || 0)
      // setValue('primeSalissure', employer?.primeSalissure || 0)
      // setValue('indCaisse', employer?.indCaisse || 0)
      // setValue('bonus', employer?.bonus || 0)
      // setValue('primePanier', employer?.primePanier || 0)
      // setValue('billetAvion', employer?.billetAvion || 0)
      // setValue('autresPrimes', employer?.autresPrimes || 0)
      setValue('visaNumber', employer?.visaNumber)
      setValue('passportNumber', employer?.passportNumber)
      // setValue('permitNumber', employer?.permitNumber)
      // setValue('contratDuration', employer?.contratDuration)

      setBirthDat(new Date(employer?.birthDate))
      setNationality(employer?.nationality)
      setSexe(employer?.sexe)
      // setcontratDuration(parseInt(employer?.contratDuration))
      setHasPermit(employer?.hasPermit ? "true" : "false")
      setvisaExpDate(new Date(employer?.visaExpDate))
      setpassportExpDate(new Date(employer?.passportExpDate))
      // setDate(new Date(employer?.permitDate))
      setOccupationName(employer?.occupation?.name)
      setDiplonme(employer?.diplome?.id)
      // setCategory(employer?.category?.id)

    } else {
      setCanEdit(true)
      setValue('indLogement', 0)
      setValue('indTransport', 0)
      setValue('indResponsabilite', 0)
      setValue('indRepresentation', 0)
      setValue('primeExploitation', 0)
      setValue('sursalaire', 0)
      setValue('primeSalissure', 0)
      setValue('indCaisse', 0)
      setValue('bonus', 0)
      setValue('primePanier', 0)
      setValue('billetAvion', 0)
      setValue('autresPrimes', 0)
      // setValue('contratDuration', 0)
      setBirthDat(null)
      setSexe(null)
      setOccupationId(null)
      setDate(null)
      setDiplonme(null)
      setpassportExpDate(null)
      setvisaExpDate(null)
      setNationality(null)
      setHasPermit('true')
    }
  }, [employer]);
  return (
    <div>
      {/* {console.log(errors)} */}
      <Dialog open={open} onClose={() => { reset(); handleClose() }}  >
        {enterprise?.length == 0 ? <div className="alert alert-danger text-center" role="alert">


          {"Désolé, votre entreprise n'est pas enregistrer dans le fichier FNTEC. Vous ne pouvez enregistrer vos employés!"}
        </div> : <form onSubmit={handleSubmit(onSubmitHandler)}>
          <DialogTitle>
            <Row className="row-cols-2 justify-content-between align-items-center ">
              <Col className="flex-fill">
                <div>{employer ? canEdit ? t("foreignworkers.addEmployer.modifier un employer") : t("foreignworkers.addEmployer.details sur l'employer") : t("foreignworkers.addEmployer.title")}</div>
              </Col>
              {employer && <Col className="d-flex justify-content-end">
                <i
                  role="button"

                  class="bi bi-pencil shadow-sm  rounded p-2 text-info"
                  onClick={() => {
                    setCanEdit(!canEdit)
                  }}
                >
                  <small>  {t('edit')}</small>

                  {/* {t("foreignworkers.addEmployer.buttons.save")} */}
                </i>
              </Col>}

            </Row>
          </DialogTitle>
          {/* {res?.success === false && (
            <div className="alert alert-danger text-center" role="alert">
              {" "}
              {res?.message}
            </div>
          )} */}
          {errorMassage?.message && (
            <div className="alert alert-danger text-center" role="alert">
              {errorMassage?.message !== "" &&
                // errorMassage?.message
                "Une erreur est produite. Veuillez réessayer plus tard"
              }

            </div>
          )}
          <DialogContent>
            {/* Infors perso */}
            <Container>
              <div>
                <h3 className="text-success">
                  {t("foreignworkers.addEmployer.personalInfos.title")}
                </h3>
                <hr />
              </div>
              <Row className="row-cols-1 row-cols-sm-2 g-2">
                <Col  >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="firstName"
                      name="firstName"
                      label={t(
                        "dashboard.table.firstName"
                      )}
                      type="text"
                      size="small"
                      fullWidth
                      variant="outlined"
                      {...register("firstName")}
                      error={errors.firstName?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
                <Col className="" >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="lastName"
                      name="lastName"
                      label={t(
                        "dashboard.table.lastName"
                      )}
                      type="text"
                      size="small"
                      fullWidth
                      variant="outlined"
                      {...register("lastName")}
                      error={errors.lastName?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
                <Col  >
                  <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      {t("foreignworkers.addEmployer.personalInfos.sexe.title")}
                    </InputLabel>
                    <Select
                      disabled={!canEdit}

                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={sexe}
                      label={t(
                        "foreignworkers.addEmployer.personalInfos.sexe.title"
                      )}
                      onChange={(event) => setSexe(event.target.value)}
                    >
                      <MenuItem value="">
                        <em>
                          {t(
                            "foreignworkers.addEmployer.personalInfos.sexe.select"
                          )}
                        </em>
                      </MenuItem>
                      <MenuItem
                        value={t(
                          "foreignworkers.addEmployer.personalInfos.sexe.m"
                        )}
                      >
                        {t("foreignworkers.addEmployer.personalInfos.sexe.m")}
                      </MenuItem>
                      <MenuItem
                        value={t(
                          "foreignworkers.addEmployer.personalInfos.sexe.f"
                        )}
                      >
                        {t("foreignworkers.addEmployer.personalInfos.sexe.f")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Col>

                <Col >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        disabled={!canEdit}

                        value={birthDat ? dayjs(birthDat) : null}
                        slotProps={{ textField: { size: "small" } }}
                        // defaultValue={dayjs("2022-04-17")}
                        onChange={(newValue) => setBirthDat(newValue)}
                        label={t(
                          "foreignworkers.addEmployer.personalInfos.birthDate"
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {/* {console.log("first", dayjs(passportExpDate).format("YYYY-MM-DD"))} */}
                </Col>
                <Col  >
                  <FormControl

                    sx={{ width: "100%" }} size="small">
                    {/* <InputLabel id="demo-select-small-label">
                      {t(
                        "foreignworkers.addEmployer.personalInfos.occupation.title"
                      )}
                    </InputLabel>
                    <Select
                      disabled={!canEdit}

                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={occupation}
                      label={t(
                        "foreignworkers.addEmployer.personalInfos.occupation.title"
                      )}
                      onChange={(event) => setOccupation(event.target.value)}
                    >
                      <MenuItem value="">
                        <em>
                          {t(
                            "foreignworkers.addEmployer.personalInfos.occupation.select"
                          )}
                        </em>
                      </MenuItem>
                      {occupations?.data &&
                        occupations.data.map((item) => {
                          return (
                            <MenuItem value={item?.id}>{item?.name}</MenuItem>
                          );
                        })}
                    </Select> */}
                    <Autocomplete
                      disabled={!canEdit}
                      value={occupationName}
                      onChange={(event, newValue) => setOccupationId(newValue?.value)}
                      disablePortal
                      freeSolo
                      id="poste"
                      options={occupations?.data?.map((occ) => { return { label: occ?.name, value: occ?.id } })}
                      // sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params}

                        label={t(
                          "foreignworkers.addEmployer.personalInfos.occupation.select"
                        )}
                        onChange={((event) => setOccupationName(event?.target?.value))}

                      />}
                    />
                  </FormControl>
                </Col>

                <Col className="" >
                  <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      {t(
                        "foreignworkers.addEmployer.personalInfos.diplome.title"
                      )}
                    </InputLabel>
                    <Select
                      disabled={!canEdit}

                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={diplome}
                      label={t(
                        "foreignworkers.addEmployer.personalInfos.diplome.title"
                      )}
                      onChange={(event) => setDiplonme(event.target.value)}
                    >
                      <MenuItem value="">
                        <em>
                          {t(
                            "foreignworkers.addEmployer.personalInfos.diplome.select"
                          )}
                        </em>
                      </MenuItem>
                      {diplomes?.data &&
                        diplomes.data.map((item) => {
                          return (
                            <MenuItem value={item?.id}>{item?.name}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Col>

                <Col  >
                  <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      {t(
                        "foreignworkers.addEmployer.personalInfos.nationality.title"
                      )}
                    </InputLabel>
                    <Select
                      disabled={!canEdit}

                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={nationality}
                      label={t(
                        "foreignworkers.addEmployer.personalInfos.nationality.title"
                      )}
                      onChange={(event) => setNationality(event.target.value)}
                    >
                      <MenuItem value="">
                        <em>
                          {t(
                            "foreignworkers.addEmployer.personalInfos.nationality.select"
                          )}
                        </em>
                      </MenuItem>
                      {nationalities &&
                        nationalities?.sort(i18n?.language == 'fr' ? (a, b) => { return a?.demonyms?.fra?.f < b?.demonyms?.fra?.f ? -1 : 1 } :
                          (a, b) => { return a?.demonyms?.eng?.f < b?.demonyms?.eng?.f ? -1 : 1 }
                        ).map((item) => {
                          return (
                            <MenuItem value={item?.demonyms?.fra?.f}>
                              {i18n?.language == 'fr' ? item?.demonyms?.fra?.f : item?.demonyms?.eng?.f}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {/* {nationalities && <Autocomplete
                      disabled={!canEdit}
                      value={occupation}

                      disablePortal
                      freeSolo
                      id="poste"
                      options={nationalities && nationalities?.map((item) => { return { label: item?.demonyms?.fra?.f , value: item?.demonyms?.fra?.f  } })}
                      // options={occupations?.data?.map((occ) => { return { label: occ?.name, value: occ?.id } })}
                     
                      // sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params}

                        label={t(
                          "foreignworkers.addEmployer.personalInfos.occupation.select"
                        )}
                        onChange={((event) => setOccupationName(event?.target?.value))}
                        
                      />}
                    />} */}

                  </FormControl>
                </Col>
              </Row>

              {/* infos sur le contrat */}
              {/*<div>
                <h3 className="text-success">
                  {t("foreignworkers.addEmployer.contactInfos.title")}
                </h3>
                <hr />
              </div>*/}
              {/*<Row className="row-cols-2">*/}
                {/* <Col className="pl-0" md="6">
                  <FormControl sx={{  width: "100%" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      {t(
                        "foreignworkers.addEmployer.contactInfos.enterprise.title"
                      )}
                    </InputLabel>
                    <Select
                    disabled={!canEdit}

                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={enterprise}
                      label={t(
                        "foreignworkers.addEmployer.contactInfos.enterprise.title"
                      )}
                      onChange={(event) => setEnterprise(event.target.value)}
                    >
                      <MenuItem value="">
                        <em>
                          {t(
                            "foreignworkers.addEmployer.contactInfos.enterprise.select"
                          )}
                        </em>
                      </MenuItem>
                      {enterprises?.data &&
                        enterprises.data.map((item) => {
                          return (
                            <MenuItem value={item?.id}>
                              {item?.socialRaison}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Col> */}

                {/*<Col >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="contratNumber"
                      name="contratNumber"
                      label={t(
                        "foreignworkers.addEmployer.contactInfos.contractNumber"
                      )}
                      type="text"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("contratNumber")}
                      error={errors.contratNumber?.message ? true : false}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="contratDuration"
                      name="contratDuration"
                      label={t(
                        "foreignworkers.addEmployer.contactInfos.contractDuration.title"

                      )}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("contratDuration")}
                      error={errors.contratNumber?.message ? true : false}
                    />
                  </Form.Group>

                </Col>
              </Row> */}
              {/* infos sur le salaire */}
              {/*<div>
                <h3 className="text-success">
                  {t("foreignworkers.addEmployer.salaryInfos.title")}
                </h3>
                <hr />
              </div>
               <Row>
                <Col className="pl-0" md="6">
                  <FormControl sx={{  width: "100%" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      {t(
                        "foreignworkers.addEmployer.salaryInfos.categorie.title"
                      )}
                    </InputLabel>
                    <Select
                      disabled={!canEdit}

                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={category}
                      label={t(
                        "foreignworkers.addEmployer.salaryInfos.categorie.title"
                      )}
                      onChange={(event) => setCategory(event.target.value)}
                    >
                      <MenuItem value="">
                        <em>
                          {t(
                            "foreignworkers.addEmployer.salaryInfos.categorie.select"
                          )}
                        </em>
                      </MenuItem>
                      {categories?.data &&
                        categories.data.map((item) => {
                          return (
                            <MenuItem value={item?.id}>{item?.name}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Col>
                <Col  md="6">
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="salary"
                      name="salary"
                      label={t(
                        "tabForms.2.salary"
                      )}
                      type="number"
                      size="small"
                      fullWidth
                      variant="outlined"

                      {...register("salary")}
                      error={errors.salary?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
              </Row> */}
              {/* <Row>
                <Col md="6">
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="salary"
                      name="salary"
                      label={t(
                        "tabForms.2.bSalary"
                      )}
                      size="small"
                      type="number"
                      fullWidth
                      variant="outlined"
                      {...register("salary")}
                      error={errors.salary?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="indLogement"
                      name="indLogement"
                      label={t("tabForms.2.indemnite de logement")}
                      type="number"
                      size="small"
                      fullWidth
                      variant="outlined"
                      {...register("indLogement")}
                      error={errors.indLogement?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="indTransport"
                      size="small"
                      name="indTransport"
                      label={t(
                        "tabForms.2.indemnite de transport"
                      )}
                      type="number"
                      fullWidth
                      variant="outlined"
                      {...register("indTransport")}
                      error={errors.indTransport?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="indResponsabilite"
                      name="indResponsabilite"
                      label={t("tabForms.2.prime de reponsabilite")}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("indResponsabilite")}
                      error={errors.indResponsabilite?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="row row-cols-2">
                <Col >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="indRepresentation"
                      name="indRepresentation"
                      label={t("tabForms.2.prime de representation")}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("indRepresentation")}
                      error={errors.indRepresentation?.message ? true : false}
                    />
                  </Form.Group>
                </Col>


                <Col >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="primeExploitation"
                      name="primeExploitation"
                      label={t("tabForms.2.prime d'expatriation")}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("primeExploitation")}
                      error={errors.primeExploitation?.message ? true : false}
                    />
                  </Form.Group>
                </Col>



                <Col >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="sursalaire"
                      name="sursalaire"
                      label={t("tabForms.2.sursalaire")}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("sursalaire")}
                      error={errors.sursalaire?.message ? true : false}
                    />
                  </Form.Group>
                </Col>



                <Col >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="primeSalissure"
                      name="primeSalissure"
                      label={t("tabForms.2.prime de salissure")}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("primeSalissure")}
                      error={errors.primeSalissure?.message ? true : false}
                    />
                  </Form.Group>
                </Col>



                <Col >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="indCaisse"
                      name="indCaisse"
                      label={t("tabForms.2.indenmite de caisse")}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("indCaisse")}
                      error={errors.indCaisse?.message ? true : false}
                    />
                  </Form.Group>
                </Col>



                <Col >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="bonus"
                      name="bonus"
                      label={t("tabForms.2.bonus")}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("bonus")}
                      error={errors.bonus?.message ? true : false}
                    />
                  </Form.Group>
                </Col>



                <Col >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="primePanier"
                      name="primePanier"
                      label={t("tabForms.2.prime de panier")}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("primePanier")}
                      error={errors.primePanier?.message ? true : false}
                    />
                  </Form.Group>
                </Col>



                <Col >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="billetAvion"
                      name="billetAvion"
                      label={t("tabForms.2.billet d'avion")}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("billetAvion")}
                      error={errors.billetAvion?.message ? true : false}
                    />
                  </Form.Group>
                </Col>

                <Col >
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="autresPrimes"
                      name="autresPrimes"
                      label={t("tabForms.2.autres primes")}
                      type="number"
                      fullWidth
                      size="small"
                      variant="outlined"

                      {...register("autresPrimes")}
                      error={errors.autresPrimes?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <>
                <div>
                  <h3 className="text-success">
                    {t("foreignworkers.addEmployer.workPermitTitle")}
                  </h3>
                  <hr />
                </div>
                <Row>
                  <Col className="" md="6">
                    <FormControl sx={{ width: "100%" }} size="small">
                      <InputLabel id="demo-select-small-label">
                        {t(
                          "foreignworkers.addEmployer.salaryInfos.hasPermit.title"
                        )}
                      </InputLabel>
                      <Select
                        disabled={!canEdit}

                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={hasPermit}
                        label={t(
                          "foreignworkers.addEmployer.salaryInfos.hasPermit.title"
                        )}
                        onChange={(event) => setHasPermit(event.target.value)}
                      >
                        <MenuItem
                          value={
                            "true"
                          }
                        >
                          {t(
                            "foreignworkers.addEmployer.salaryInfos.hasPermit.yes"
                          )}
                        </MenuItem>
                        <MenuItem
                          value={"false"}
                        >
                          {t(
                            "foreignworkers.addEmployer.salaryInfos.hasPermit.no"
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
                {hasPermit == 'true' && <Row className="row-cols-1 row-cols-sm-2">
                  <Col >
                    <Form.Group>
                      <TextField
                        disabled={!canEdit}

                        autoFocus
                        margin="dense"
                        id="permitNumber"
                        name="permitNumber"
                        label={t(
                          "foreignworkers.addEmployer.salaryInfos.permitNumber"
                        )}
                        size="small"
                        // type="number"
                        fullWidth
                        variant="outlined"
                        {...register("permitNumber")}
                        error={errors.permitNumber?.message ? true : false}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          disabled={!canEdit}

                          value={date ? dayjs(date) : null}

                          // defaultValue={dayjs("2022-04-17")}
                          slotProps={{ textField: { size: "small" } }}
                          onChange={(newValue) => setDate(newValue)}
                          label={t(
                            "foreignworkers.addEmployer.salaryInfos.signPermitDate"
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Col>
                </Row>}
              </>

              <>
                <div>
                  <h3 className="text-success">
                    {t("foreignworkers.addEmployer.residencePermitNumberTitle")}
                  </h3>
                  <hr />
                </div>
                <Row>
                  <Col className="" md="6">
                    <FormControl sx={{ width: "100%" }} size="small">
                      <InputLabel id="demo-select-small-label">
                        {t(
                          "foreignworkers.addEmployer.hasResidentPermit"
                        )}
                      </InputLabel>
                      <Select
                        disabled={!canEdit}

                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={hasResidentPermit}
                        label={t(
                          "foreignworkers.addEmployer.hasResidentPermit"
                        )}
                        onChange={(event) => setHasResidentPermit(event.target.value)}
                      >
                        <MenuItem
                          value={
                            "true"
                          }
                        >
                          {t(
                            "foreignworkers.addEmployer.salaryInfos.hasPermit.yes"
                          )}
                        </MenuItem>
                        <MenuItem
                          value={"false"}
                        >
                          {t(
                            "foreignworkers.addEmployer.salaryInfos.hasPermit.no"
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Col> 
                </Row>
                {hasResidentPermit == 'true' && <Row className="row-cols-1 row-cols-sm-2">
                  <Col >
                    <Form.Group>
                      <TextField
                        disabled={!canEdit}

                        autoFocus
                        margin="dense"
                        id="residencePermitNumber"
                        name="residencePermitNumber"
                        label={t(
                          "foreignworkers.addEmployer.residencePermitNumber"
                        )}
                        size="small"
                        // type="number"
                        fullWidth
                        variant="outlined"
                        {...register("residencePermitNumber")}
                        error={errors.residencePermitNumber?.message ? true : false}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          disabled={!canEdit}

                          value={residencePermitExpDate ? dayjs(date) : null}

                          // defaultValue={dayjs("2022-04-17")}
                          slotProps={{ textField: { size: "small" } }}
                          onChange={(newValue) => setResidencePermitExpDate(newValue)}
                          label={t(
                            "foreignworkers.addEmployer.residencePermitExpDate"
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Col>
                </Row>}
              </> */}
              {/* Informations sur le visa */}
              <div>
                <h3 className="text-success">
                  {t("foreignworkers.addEmployer.visaInfos.title")}
                </h3>
                <hr />
              </div>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="visaNumber"
                      name="visaNumber"
                      label={t(
                        "foreignworkers.addEmployer.visaInfos.visaNumber"
                      )}
                      type="text"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("visaNumber")}
                      error={errors.visaNumber?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        disabled={!canEdit}

                        value={visaExpDate ? dayjs(visaExpDate) : null}
                        // defaultValue={dayjs("2022-04-17")}
                        slotProps={{ textField: { size: "small" } }}
                        onChange={(newValue) => setvisaExpDate(newValue)}
                        label={t(
                          "foreignworkers.addEmployer.visaInfos.visaExpDate"
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Group>
                    <TextField
                      disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="passportNumber"
                      name="passportNumber"
                      label={t(
                        "foreignworkers.addEmployer.visaInfos.passportNumber"
                      )}
                      type="text"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("passportNumber")}
                      error={errors.passportNumber?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        disabled={!canEdit}

                        value={passportExpDate ? dayjs(passportExpDate) : null}
                        // defaultValue={dayjs("2022-04-17")}
                        slotProps={{ textField: { size: "small" } }}
                        onChange={(newValue) => setpassportExpDate(newValue)}
                        label={t(
                          "foreignworkers.addEmployer.visaInfos.passportExpDate"
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Col>
              </Row>

              {/* <Row>
                <Col  md="6">
                  <Form.Group>
                    <TextField
                    disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="resLongitude"
                      name="resLongitude"
                      label={t("foreignworkers.addEmployer.visaInfos.long")}
                      type="text"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("resLongitude")}
                      error={errors.resLongitude?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
                <Col  md="6">
                  <Form.Group>
                    <TextField
                    disabled={!canEdit}

                      autoFocus
                      margin="dense"
                      id="resLatitude"
                      name="resLatitude"
                      label={t("foreignworkers.addEmployer.visaInfos.lat")}
                      type="text"
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...register("resLatitude")}
                      error={errors.resLatitude?.message ? true : false}
                    />
                  </Form.Group>
                </Col>
              </Row> */}
            </Container>
          </DialogContent>
          <DialogActions className="justify-content-between">
            <Button
              type="button"
              onClick={() => {
                reset()
                handleClose();
                // window.location.reload();
              }}
              class="btn btn-outlined btn-danger mt-3"
            >
              {t("foreignworkers.addEmployer.buttons.close")}
            </Button>
            {loading ? (
              <div class="spinner-border text-info" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              <Button
                disabled={!canEdit}
                type="submit" class="btn btn-outlined btn-success mt-3">
                {t("foreignworkers.addEmployer.buttons.save")}
              </Button>
            )}
          </DialogActions>
        </form>}
      </Dialog>
    </div>
  );
};

export default AddEmployerModal;
